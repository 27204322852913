.con-class1 {
    font-size: large;
    color: rgb(5, 12, 89);
    font-weight: bold;
}

.con-class2 {
    color: rgb(110,43,127);
    margin: 10px;
    padding: 0%;
}
.con-class2b {
    background-color: rgb(253,246,249);
    padding: 20px
}

.con-class3 {
    border-right: rgb(5, 12, 89) solid;
}

.con-class4 {
    color: darkslateblue;
    font-size: xx-large;
}

.con-class5 {
    color: rgb(110,43,127);
    font-family: monospace;
    font-size: larger;
}

.con-class6 {
    color: black;
    font-size: 16px;
    padding: 30px;
    text-align: justify;
}

.grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
}

.grid-item {
    text-align: center;
    padding: 10px;
}

.grid-container_2 {
    display: grid;
    grid-template-columns: 50% 50%;
}

.grid-item_2 {
    text-align: center;
}

.subhead+.display-2 {
    margin-top: 0.6rem;
}

.subhead {
    font-family: "montserrat-bold", sans-serif;
    font-size: 1.7rem;
    line-height: 1.333;
    text-transform: uppercase;
    letter-spacing: 0.25rem;
    color: #70a6e3;
    margin-top: 0;
    margin-bottom: 0;
}

.subhead--dark {
    color: rgb(5, 12, 89);
}


.grid-container_3 {
    display: grid;
    grid-template-columns: 35% 65%;
}

.grid-item_3 {
    text-align: center;
}

.grid-container_5 {
    display: grid;
    grid-template-columns: auto auto;
    display: flex;
}

.grid-item_5 {
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .grid-container {
        grid-template-columns: 100%;
    }

    .grid-container_2 {
        grid-template-columns: 100%;
    }

    .grid-container_3 {
        grid-template-columns: 100%;
    }

    .grid-container_5 {
        grid-template-columns: 100%;
    }
}
.iframe-class{
    border-radius:10px;
    padding-right:20px;
    padding-left:20px;
    height:80%;
}
@media screen and (max-width:1030px){
    .grid-container_5{
        display:flex;
        /* flex-direction: column; */
    }
    .iframe-class{
        width:50vh;
    }
}
@media screen and (max-width:900px){
    .grid-container_5{
        display:flex;
        /* flex-direction: column; */
    }
    .iframe-class{
        width:50vh;
    }
    .con-class6{
        font-size:14.55px;
        padding-left:10px;
        padding-right:10px
    }
}
@media screen and (max-width:900px){
    .grid-container_5{
        display:flex;
    }
    .iframe-class{
        width:50vh;
    }
    .con-class6{
        font-size:14.55px;
        padding-left:10px;
        padding-right:10px
    }
}
@media screen and (max-width:780px){
    .grid-container_5{
        display:flex;
        flex-direction: column;
    }
    .iframe-class{
        width:100%;
        height:50vh;
        border:1px solid rgba(110,43,127,0.5);
        padding: 0px;
        margin-bottom: 7px;
    }
    .con-class6{
        font-size:14.55px;
        padding-left:10px;
        padding-right:10px
    }
    .con-class3 {
        border-right: none;
    }
}
@media screen and (max-width:430px){
    .grid-container_5{
        display:flex;
        flex-direction: column;
    }
    .iframe-class{
        width:100%;
        height:65vh;
        border:1px solid rgba(110,43,127,0.5);
        padding: 0px;
        margin-bottom: 7px;
    }
    .con-class6{
        font-size:13.75px;
        padding-left:10px;
        padding-right:10px
    }
}