.fwhite {
    color: white;
}

.navlist {
    display: flex;
    justify-content: space-around;
    list-style: none;
}

.navlist-i {
    gap: 10px;
    display: flex;
    justify-content: space-around;
    margin-right: 2rem;
}

.techhead {
    margin-left: 10px;
    margin-bottom: 8px;
}

.techcon {
    justify-content: center;
    justify-content: space-evenly;
    justify-items: center;
    text-align: center;
    font-size: 17px;
    width: 100%;
    margin-bottom: 10px;
}
.techmainn{
    display: block;
    position: relative;
    width:100%;
}
.techcon2 {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}
@media screen and (max-width:700px) {
    .techcon2 {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: auto auto auto;
    }
}
@media screen and (max-width:408px) {
    .techcon2 {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: auto auto;
    }
}
.techname {
    transition: 500ms;
    border: 1px solid rgb(0, 51, 255);
    border-radius: 10px;
    box-shadow: 2px 0px 10px 1px rgb(0, 71, 90);
    padding: 3px 4px;
    margin: 9px;
    color: rgb(218, 229, 227);
    margin-bottom: 13px;
}
@media screen and (max-width:330px) {
    .techcon2 {
        margin-bottom: 5px;
        display: grid;
        grid-template-columns: auto auto;
    }
    .techname {
        border: 1px solid rgb(0, 51, 255);
        border-radius: 10px;
        box-shadow: 2px 0px 10px 1px rgb(0, 71, 90);
        padding: 3px 2px;
        margin:4px;
        color: rgb(218, 229, 227);
        margin-bottom: 5px;
        font-size:13px;
    }
}
.techname:hover{
    transform: scale(1.3);
    transition: 500ms;
}



.nav-cont {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    justify-self: center;
    gap: 1rem;
    margin-top: 10px;
    margin-left: 25px;
}

.logo-sub {
    text-decoration: none;
    color: red;
    text-decoration-line: none;
}

.logo-sub2 {
    font-size: 12px;
    text-decoration: double;
    text-decoration-style: wavy;
    font-style: italic;
}

.linksnav {
    text-decoration: none;
    color: rgb(211, 210, 210);
    transition: 300ms;
}

.linksnav:hover {
    color: rgb(138, 214, 255);
    transform: scale(1.2);
    transition: 300ms;
}

.logo-img {
    width: 5.25rem;
    height: auto;
}

.nav-class {
    background-color: rgb(4, 0, 16);
}

body {
    background-color:rgb(253,246,249);
}

.menu-top-nav {
    display: none;
}

.menu-top {
    cursor: pointer;
    right: 10px;
    top: 10px;
    margin: 10px;
    width: 20px;
    height: 20px;
}

.menu-top:hover {
    transform: skew(10deg, 10deg) scale(1.1);
    transition: 200ms;
}

.ul-list-top {
    display: flex;
}


.block {
    display: block;
}

.list-nav {
    background: linear-gradient(45deg, rgb(0, 0, 97), rgb(67, 0, 0));
    gap: 50px;
    text-decoration: none;
    list-style: none;
    padding: 10px;
    text-align: left;
    position: relative;
}

.nav-item-menu {
    position: relative;
    padding-inline-start: 0%;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 10px;
    margin-left: 6px;
}

.nav-item-menu:hover {
    border: 2px solid #c7c7c7;
}

.nav-item-menu::before {
    content: '';
    position: absolute;
    inset: 0 8px;
    border-left: 2px solid #c7c7c7;
    border-right: 2px solid #c7c7c7;
    transition: 0.5s;
}

.nav-item-menu::after {
    content: '';
    position: absolute;
    inset: 8px 0px;
    border-top: 2px solid #c7c7c7;
    border-bottom: 2px solid #c7c7c7;
    transition: 0.5s;
}

.menu-itemp {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.menu-links {
    text-decoration: none;
    color: rgb(211, 210, 210);
    transition: 300ms;
}

.hero-section {
    position: relative;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-div1 {
    position: absolute;
    top: 127px;
    inset: 0;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-items: start;
    gap: 1.25rem;
}

.hero-div2 {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
}

.hero-div3 {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: rgb(0, 183, 255);
    box-shadow: 0rem 0rem 3rem 1rem rgba(0, 183, 255, 0.882);
    animation: animateh3 4s linear infinite;
    animation-direction: alternate-reverse;
}

.hero-div4 {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: rgb(221, 0, 255);
    box-shadow: 0rem 0rem 3rem 1rem rgb(221, 0, 255);
    animation: animateh1 2s linear infinite;
    animation-direction: alternate-reverse;
}

@keyframes animateh1 {
    0% {
        box-shadow: 0rem 0rem 3rem 1rem rgb(221, 0, 255);
    }

    100% {
        box-shadow: 0rem 0rem 2.2rem 1.2rem rgb(221, 0, 255);

    }
}

@keyframes animateh3 {
    0% {
        box-shadow: 0rem 0rem 2.2rem 1.2rem rgba(0, 183, 255, 0.882);
        ;
    }

    100% {
        box-shadow: 0rem 0rem 3rem 1rem rgba(0, 183, 255, 0.882);
        ;

    }
}

@keyframes animateh2 {
    0% {
        box-shadow: 0rem 0rem 3rem 1rem rgb(255, 170, 0);
    }

    100% {
        box-shadow: 0rem 0rem 2.2rem 1.2rem rgb(255, 170, 0);

    }
}

.jnvefd {
    margin-top: 20px;
}

.onvonws {
    margin-top: 60px;
}

.hero-div5 {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: rgb(255, 170, 0);
    box-shadow: 0rem 0rem 3rem 1rem rgb(255, 170, 0);
    animation: animateh2 4s linear infinite;
    animation-direction: alternate-reverse;
}

.hero-h1span:hover {
    color: lightskyblue;
}

.hero-div3s {
    text-align: center;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    margin-top: 10px;
}

.hero-div3b {
    width: 0.25rem;
    height: 15rem;
    background: rgba(0, 183, 255, 0.882);
    background: linear-gradient(-90deg, rgba(0, 183, 255, 0.882) 0%, rgba(60, 51, 80, 0) 100%);
    background: -webkit-linear-gradient(-90deg,
            rgba(0, 183, 255, 0.882) 0%,
            rgba(60, 51, 80, 0) 100%);
    margin-left: 5px;
}

.hero-div4b {
    width: 0.25rem;
    height: 10rem;
    background: rgb(221, 0, 255);
    background: linear-gradient(-90deg, rgb(221, 0, 255) 0%, rgba(60, 51, 80, 0) 100%);
    background: -webkit-linear-gradient(-90deg,
            rgb(221, 0, 255) 0%,
            rgba(60, 51, 80, 0) 100%);
    margin-left: 5px;
}

.hero-div5b {
    width: 0.25rem;
    height: 20rem;
    background: rgb(255, 170, 0);
    background: linear-gradient(-90deg, rgb(255, 170, 0) 0%, rgba(60, 51, 80, 0) 100%);
    background: -webkit-linear-gradient(-90deg,
            rgb(255, 170, 0) 0%,
            rgba(60, 51, 80, 0) 100%);
    margin-left: 5px;
    margin-left: 5px;
}

.hero-h1span {
    color: #ffaa01;
    z-index: 1010;
    animation: hero-h1spana 4s linear infinite;
    animation-direction: alternate-reverse;
}

@keyframes hero-h1spana {
    0% {
        color: #ffaa01;
    }
    100% {
        color: #ff00e1;
    }
}

.hero-h1span:hover {
    background: -webkit-linear-gradient(30deg, #ff00e1, #00f2ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-head-top {
    margin-top: 30px;
}

.hero-subhead {
    color: white;
}

.About-intros {
    margin-top: 1rem;
    color: rgb(223, 223, 223);
    font-size: 17px;
    line-height: 30px;
    margin-left: 10px;
    margin-right: 14px;

}

.hero-bc-01 {
    position: absolute;
    bottom: 8rem;
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    color: white;
    z-index: 300;
    top: 80vh;
}

.hero-bc-03 {
    height: 30px;
}

.hero-bc-02 {
    animation: animate-arrow 1.5s linear infinite;
    display: flex;
    flex-direction: column;
    animation-direction: reverse;
}

@keyframes animate-arrow {
    0% {
        margin-bottom: 0px;
        height: 20px;
    }

    40% {
        height: 30px;
        color: white;
        weight: 700;
    }

    100% {
        color: red;
        height: 40px;
        margin-bottom: 60px;
    }
}

.danger-div-about {
    height: 50px;
    width: 100px;
    color: pink;
    justify-content: center;
    text-align: center;
}

.service-parent {
    position: relative;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    display: grid;
    grid-template-columns: auto auto;
}

.about-tilt-01 {
    width: 100%;
}

.about-tilt-02 {
    width: 100%;
    padding: 1px;
    border-radius: 20px;
}

.about-tilt-02:hover {
    box-shadow: 0px 6px 20px 5px rgb(141, 141, 141);
}

.about-tilt-03 {
    background: linear-gradient(30deg, rgb(132, 15, 15), rgb(26, 26, 179));
    border-radius: 20px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.codebracketicon {
    height: 45px;
    color: white;
    font-weight: 700;
    text-shadow: 0px 0px 5px 5px white;
}

.about-tilt-04 {
    font-size: 20px;
}

.experience-01 {
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    color: rgb(90, 90, 90);
}

.experiencep-01 {
    margin-top: 15px;
    text-align: center;
}

.experiencep-02 {
    text-align: center;
}

.experience-02 {
    width: 45px;
    z-index: -22999;
    height: 45px;
    object-fit: contain;
}

.nolgtb {
    list-style: none;
}

.container-work {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
    margin-top: 25px;
    padding: 30px;
}

.p-work {
    z-index: 2000;
    color: white;
}

.container-work .card-work {
    max-width: 300px;
    position: relative;
    background: linear-gradient(30deg, rgb(130, 36, 36), rgb(45, 45, 119));
    color: white;
    height: 155px;
    margin: 30px 10px;
    padding: 20px auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 2px rgba(255, 255, 255, 0.5);
    transition: 0.6s ease-in-out;
}

.works-m1 {
    text-align: center;
}

.works-m2 {
    text-align: center;
}

.container-work .card-work:hover {
    height: 420px;
    box-shadow: 0 6px 25px 10px rgba(0, 55, 255, 0.5);
    z-index: 1000;
}

.card-work:hover {
    z-index: 9990;
    position: relative;
}

.constent-head-work2 {
    overflow: visible;
    transition: 500ms;
}

.constent-head-work {
    transition: 0.5s;
}

.imgBx-work:hover {
    background-color: transparent;
    background: transparent;
}

.container-work {
    width: 100%;
}

.container-work .card-work .imgBx-work {
    position: relative;
    width: 260px;
    height: 260px;
    top: -60px;
    left: 20px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom, black, black, grey, rgb(175, 175, 175));
}

.container-work .card-work .imgBx-work img {
    max-width: 100%;
    border-radius: 4px;
}

.img-work2 {
    overflow: hidden;
    width: 20rem;
    height: 10rem
}

.container-work .card-work .content-work {
    position: relative;
    margin-top: -30px;
    padding: 10px 15px;
    text-align: center;
    color: white;
    opacity: 0;
    transition: 0.3s ease-in-out;
    color: white;

}

.constent-head-work {
    max-height: 40px;
    overflow: hidden;
}

.container-work .card-work:hover .content-work {
    opacity: 1;
    margin-top: -40px;
    margin-top: -40px;
    transition-delay: 0.5s;
}

.content-p-work {
    z-index: 2000;
    color: white;
    text-align: center;
}

.work-linkk2 {
    text-decoration: none;
    background-color: rgb(42, 0, 81);
    color: white;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    margin: auto;
    width: 160px;
    border-radius: 5px;
    transition: 400ms;
}

.feed-headn {
    padding-left: 1.5%;
    font-size: 22px;
    font-weight: 600;
}

.work-linkk {
    text-decoration: none;
    background-color: rgb(42, 0, 81);
    color: white;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    margin: auto;
    width: 160px;
    border-radius: 5px;
    display: none;
}

.work-linkk:hover {
    transition: 400ms;
    background-color: #004447;
    color: white;
}

.techn {
    text-align: center;
    color: #001ea2;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: auto;
    margin-top: 30px;
    justify-self: center;
}

.card-work {
    overflow: visible;
}


.jfkxdkn {
    display: flex;
    justify-items: center;
    gap: 1px;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}

.constent-head-word {
    display: flex;
    justify-content: center;
    text-align: center;
}

.content-p2-work {
    text-align: center;
    display: flex;
    position: absolute;
}

.content-p2-work2 {
    display: none;
}

.content-p2-work2:hover {
    display: block;
}


@media screen and (max-width:800px) {
    .service-parent {
        position: relative;
        /* width:100%; */
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 5rem;
        /* margin-bottom: 10%; */
        display: flex;
        flex-wrap: wrap;
        gap: 2.5rem;
        justify-content: center;
        align-items: center;
        align-content: center;
        justify-items: center;
        margin: auto;
        display: grid;
        grid-template-columns: auto auto;
        /* width:100%; */
    }
}


.feedback-body {
    display: flex;
    align-items: center;
    min-height: 60vh;
    background-color: rgb(12, 1, 43);
    border-radius: 30px;
    width: 100%;
}

.feedback-body:hover {

    box-shadow: 0px 6px 11px 2px rgb(1, 63, 126);
}

.feedback-body {
    width: 100%;
    overflow-x: scroll;
}

.feedback-body::-webkit-scrollbar {
    color: red;
    display: none;
}

.container-feedback {
    position: relative;
    display: flex;
    padding: 80px 40px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px;
}

.card-feedback {
    position: relative;
    width: 350px;
    height: 100px;
    background: brown;
    transition: 500ms;
}

.card-feedback:hover {
    height: 450px;

}

.card-feedback .feedback-lines {
    position: absolute;
    inset: 0;
    background: rgb(174, 137, 0);
    overflow: hidden;
}

.card-feedback:hover .feedback-lines {
    background: rgba(255, 166, 0, 0.823);

}

.card-feedback .feedback-lines::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 120px;
    background: linear-gradient(transparent, rgb(209, 5, 255), rgb(81, 81, 196), rgb(151, 151, 202), transparent);
    animation: animatefeed 4s linear infinite;
    animation-play-state: paused;
}

.card-feedback:hover .feedback-lines::before {
    animation-play-state: running;
}

.card-feedback .feedback-lines::after {
    content: '';
    position: absolute;
    inset: 3px;
    background: linear-gradient(30deg, rgb(80, 4, 4), rgb(0, 0, 87));
}

.card-feedback .feedback-imgBx {
    position: absolute;
    left: 50%;
    top: -60%;
    width: 150px;
    height: 150px;
    transform: translateX(-50%);
    background: grey;
    transition: 500ms;
    z-index: 10;
    overflow: hidden;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.card-feedback:hover .feedback-imgBx {

    background: rgb(0, 123, 255);
    top: 25px;
    width: 150px;
    height: 150px;
}

.card-feedback .feedback-imgBx::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 150px;
    background: linear-gradient(transparent, red, rgb(251, 144, 144), transparent);
    animation: animatefeed2 5s linear infinite;
    animation-play-state: paused;
}

.card-feedback:hover .feedback-imgBx::before {
    animation-play-state: running;
}

@keyframes animatefeed2 {
    0% {
        transform: translate(-50%, -50%) rotate(360deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
}

.card-feedback .feedback-imgBx::after {
    content: '';
    position: absolute;
    inset: 3px;
    background-color: black;
}

.card-feedback .feedback-imgBx img {
    position: absolute;
    z-index: 1;
    filter: grayscale(1);
    opacity: 0.5s;
    transition: 0.5;
    width: 100px;
}

.card-feedback:hover .feedback-imgBx img {
    opacity: 1;
    filter: none;
    transition: 400ms;
}

.card-feedback .feedback-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 44px;
}

.card-feedback:hover .feedback-content {
    height: 100%;
    opacity: 1;
}

.card-feedback {
    height: 120px;
}

.card-feedback:hover {
    box-shadow: 0px 0px 10px 10px rgb(0, 0, 0);
}

.card-feedback .feedback-content .feedback-details {
    padding: 30px 20px;
    text-align: center;
    width: 100%;
    transition: 500ms;
    transform: translateY(145px);
}

.card-feedback:hover .feedback-content .feedback-details {
    transform: translateY(0px);
}

.card-feedback .feedback-content .feedback-details h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: grey;
    line-height: 1.2rem;
}

.card-feedback:hover .feedback-content .feedback-details h2 {
    color: white;

}

.card-feedback .feedback-content .feedback-details p {
    color: rgb(198, 198, 198);
    opacity: 0;
    transition: 500ms;
}

.card-feedback:hover .feedback-content .feedback-details p {
    opacity: 1;
}

.feedback-pa {
    overflow: hidden;
    display: none
}

.card-feedback:hover .feedback-pa {
    display: flex;
}

.card-feedback .feedback-content .feedback-details a {
    display: inline-block;
    padding: 8px 15px;
    background: black;
    color: grey;
    margin-top: 10px;
    font-weight: 500;
    text-decoration: none;
    opacity: 0;
    transition: 500ms;
}

.card-feedback:hover .feedback-content .feedback-details a {
    display: inline-block;
    padding: 8px 15px;
    background: black;
    color: white;
    margin-top: 10px;
    font-weight: 500;
    text-decoration: none;
    opacity: 0;
    transition: 500ms;
}

.card-feedback .feedback-content .feedback-details p,
.card-feedback:hover .feedback-content .feedback-details a {
    opacity: 1;
}





@keyframes animatefeed {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}



/* Feedback STYLING ENDS HERE */


/* Contact form */
.contact-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.5rem;
    overflow: hidden;
}

.contact-subcontainer {
    flex: 0.75;
    background: black;
    padding: 2rem;
    border-radius: 1rem;
}

.contact-form {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.contact-label {
    display: flex;
    flex-direction: column;
}

.contact-form-span {
    color: white;
    font-weight: 500;
    margin-bottom: 1rem;
}

.contact-form-input {
    --tw-bg-opacity: 1;
    background-color: rgb(21 16 48 / var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    border-radius: 0.5rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    font-weight: 500;
    border-style: none;
}

.contact-form-input\:text-secondary::placeholder {
    --tw-text-opacity: 1;
    color: rgb(170 166 195 / var(--tw-text-opacity));
}

.contact-form-button {
    --tw-bg-opacity: 1;
    background-color: rgb(21 16 48 / var(--tw-bg-opacity));
    padding-top: 0.75rem
        /* 12px */
    ;
    padding-bottom: 0.75rem
        /* 12px */
    ;
    padding-left: 2rem
        /* 32px */
    ;
    padding-right: 2rem
        /* 32px */
    ;
    border-radius: 0.75rem
        /* 12px */
    ;
    outline: 2px solid transparent;
    outline-offset: 2px;
    width: fit-content;
    color: white;
    font-weight: 700;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: #050816;
    --tw-shadow: var(--tw-shadow-colored);
}

.contact-motion-div {
    height: 350px;
    flex: 1 1 0%;
    height: auto;
}

/* Contact form  Ends */
/* stars */
.stars-div1 {
    height: 80vh;
    width: 100%;
    /* overflow: visible; */
    border: none
}

.iframec {
    /* overflow: visible; */
    border: none;
}

.iframec::-webkit-scrollbar {
    display: none;
    color: red;
}

.stars-div1::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width:600px) {
    .stars-div1 {
        height: 70vh;
        width: 100%;
        /* overflow: visible; */
        border: none
    }

    .iframec {
        width: 100%;
        height: 100%;
        /* overflow: visible; */
        border: none;
    }

    .iframec::-webkit-scrollbar {
        display: none;
        color: red;
    }

    .stars-div1::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width:930px) {
    .menu-top-nav {
        display: flex;
        justify-content: end;
        justify-items: center;
    }

    .ul-list-top {
        display: none;
    }

    .About-into {
        font-size: 12px;
        text-align: justify;
    }
}

@media screen and (max-width:700px) {
    .contact-motion-div {
        height: 500px;
    }

    .contact-container {
        margin-top: 3rem;
        flex-direction: row;
    }

    .about-tilt-04 {
        font-size: 15px;
    }

    .menu-top-nav {
        display: flex;
        justify-content: end;
        justify-items: center;
    }

    .ul-list-top {
        display: none;
    }

    .About-into {
        font-size: 12px;
        text-align: justify;
    }
}

.cbl1 {
    text-decoration: none;
    color: grey;
    transition: 400ms;
}

.cbl1:hover {
    color: white;
    scale: 1.1;
    transition: 400ms;
}

@media screen and (max-width:400px) {
    .container-feedback:hover .feedback-imgBx {
        margin-top: -45px;
    }

    .container-feedback {
        position: relative;
        display: flex;
        padding: 80px 40px;
        justify-content: center;
        /* align-items: center; */
        flex-wrap: wrap;
        gap: 80px;
        /* margin-left: 0px; */
        width: 350px;

    }

    .about-tilt-04 {
        font-size: 12px;
    }

    .about-tilt-01 {
        width: 300px;
        /* height:100px;    */
    }

    .service-parent {
        margin-top: 5rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2.5rem;
        display: grid;
        grid-template-columns: auto;
    }

    .hero-section {
        position: relative;
        width: 100%;
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
        /* background-image: url('../assets/herobg1.png'); */
        background-repeat: no-repeat;
        background-size: cover;
    }

    .service-parent {
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 5rem;
    }

    .about-tilt-01 {
        font-size: 17px;
        scale: 0.8;
        margin-top: -80px;
        padding: -100px;
    }
}



/* Earth Model */

.marker:before {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    position: absolute;
    left: 50%;
    top: -50%;
}

.app-wrapper {
    position: fixed;
    left: 0;
    top: 0;
}

.markers {
    position: relative;
    padding: 0;
    list-style-type: none;
    background-color: #ff00e1;
}

.marker {
    color: white;
    font-weight: 300;
    font-size: 14px;
    background-color: red;
}


.dg.a {
    margin-top: 10px;
    min-width: 300px;
    background-color: #00f2ff;
}
.experience-03{
    font-weight: 700;

}

.footer1 {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  
  @media screen and (max-width:750px) {
    .footer1 {
      display: grid;
      grid-template-columns: auto auto;
      /* display: none; */
    }
  }
  
  @media screen and (max-width:530px) {
    .bsh {
      font-size: 16px;
    }
  
    .bsc {
      font-size: 13px;
    }
  }
  
  @media screen and (max-width:400px) {
    .bsh {
      font-size: 16px;
    }
  
    .bsc {
      font-size: 12px;
    }
  
    .disf2 {
      display: flex;
      flex-direction: column;
      flex-direction: column-reverse;
      margin-left: 0px;
    }
  
    .margin-bottom-footer2 {
      margin-bottom: 15px;
    }
  }
  
  svg:hover{
    fill:#08D4F8;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  .eveimg{
    border: 2px solid rgb(5, 12, 89);
  }