.con-cil1 {
    color: #70A6E3;
}

.con-cil2 {
    margin-top: 1rem;
}

.con-cil3 {
    color: orangered;
    background-color: whitesmoke;
    padding: 0.4rem;
    border-radius: 4px;
}

.con-cil4 {
    color: whitesmoke;
    background-color: blue;
    padding: 0.4rem;
    border-radius: 4px;
}

.con-cil5 {
    color: dimgray;
}

.chair {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;
}

.chairimg {
    z-index: 999;
}

.concontainer {
    height: 15rem;
    width: 15rem;
    cursor: pointer;
}

.chair-head {
    margin-top: -85px;
    text-align: center;
    width: 100%;
    z-index: -1;
}

.chair-name {
    font-size: 20px;
    font-weight: 700;
    color: rgb(110, 43, 127);
}

.chair {
    z-index: 999;
    position: relative;
}

.chair-pos {
    z-index: -1;
    color: rgb(5, 12, 89);
    font-size: 13px;
    font-weight: 500;
}

.chair-head {
    display: none;
    transition: 500ms;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}

.concontainer:hover .chair {
    transition: 500ms;
    z-index: 999;
    transform: translate(-2rem, -2rem) scale(1.051);
    box-shadow: 2px 10px 10px rgba(5, 12, 89, 0.5);
}

.concontainer:hover .chair-head {
    display: block;
    transition: 5000ms;
    z-index: -1;
    transform: translate(1rem, 4rem);
    animation: chairani 600ms forwards ease-in-out;
}

/* --------- */

/* *,*:after,*:before{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
} */
/* body{
	font-family: arial;
	font-size: 16px;
	margin:0;
	background: #34aff3;
	display: flex;
	align-items: center;
	justify-content: space-around;
	min-height: 100vh;
} */
.conti {
    flex-wrap: wrap;
}

.imgs {
    max-width: 100%;
}

.ui-card {
    margin-bottom: 15px;
    width: 280px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    border: solid 1px rgb(110, 43, 127);
    background: radial-gradient(#111 50%, #000 100%);
    background: linear-gradient(to top, rgb(3, 13, 118), #111 50%);
}

.ui-card:hover {
    box-shadow: 0px 0px 10px rgb(34, 0, 114);
}

.ui-card img {
    transform: scale(1.2);
    transition: all 0.3s ease-out;
}

.ui-card:hover img {
    transform: scale(1.1) translateY(10px);
    opacity: 0.3;
}

.ui-card .description {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    text-align: center;
    color: #e2e1e1;
    font-size: 18px;
}

.ui-card .description h3 {
    font-weight: 700;
    font-size: 32px;
    margin: 0 0 20px;
    transform: translateY(30px);
    opacity: 0;
    transition: all 0.3s ease-out;

}

.ui-card .description h5 {
    font-weight: 700;
    font-size: 25px;
    margin: 0 0 20px;
    transform: translateY(30px);
    opacity: 0;
    transition: all 0.3s ease-out;
}

.ui-card .description p {
    transform: translateY(30px);
    opacity: 0;
    transition: all 0.3s ease-out 0.2s;
}

.ui-card .description a {
    color: #fff;
    /* background:#34aff3; */
    display: inline-block;
    padding: 10px 25px;
    border-radius: 5px;
    text-decoration: none;
    transform: translateY(30px);
    opacity: 0;
    transition: all 0.3s ease-out 0.4s;
}

.flex {
    display: flex;
}

.ui-card .description a:hover {
    /* background:transparent; */
}

.ui-card:hover .description h3 {
    opacity: 1;
    transform: translateY(0px);
}

.ui-card:hover .description h5 {
    opacity: 1;
    transform: translateY(0px);
}

.ui-card:hover .description p {
    opacity: 1;
    transform: translateY(0px);
}

.ui-card:hover .description a {
    opacity: 1;
    transform: translateY(0px);
}


/* --------- */

@keyframes chairani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.conti {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.chair-social {
    width: 100%;
    justify-content: center;
    gap: 5px;
    margin-right: 38px;
}

.chairlinks {
    color: rgb(206, 206, 206);
}

.chairclassa:hover {
    color: rgb(238, 238, 238);
}

.chair {
    overflow: hidden;
}

@media screen and (max-width:720px) {


    .concontainer {
        margin-top: 26px;
    }
}

@media screen and (max-width:520px) {


    .concontainer {
        margin-top: 26px;
    }

    .chair {
        height: 90%;
        width: 90%;
    }

    .chair-head {
        transform: translate(-1rem);
    }
}

@media screen and (max-width:810px) {
    .ui-card {
        width: 240px;
    }
}
@media screen and (max-width:611px) {
    .ui-card {
        width: 200px;
    }
    .ui-card .description h5 {
        font-weight: 600;
        font-size: 20px;
        margin: 0 0 10px;
        transform: translateY(30px);
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    
    .ui-card .description p {
        transform: translateY(30px);
        /* opacity: 0; */
        font-size: 15px;
        transition: all 0.3s ease-out 0.2s;
    }
}
@media screen and (max-width:370px) {
    .ui-card {
        width: 170px;
    }
    .ui-card .description h5 {
        font-weight: 600;
        font-size: 20px;
        margin: 0 0 10px;
        transform: translateY(30px);
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    
    .ui-card .description p {
        transform: translateY(30px);
        /* opacity: 0; */
        font-size: 15px;
        transition: all 0.3s ease-out 0.2s;
        padding-bottom: 5px;
    }
    .ui-card .description p p {
        transform: translateY(30px);
        /* opacity: 0; */
        font-size: 13px;
        transition: all 0.3s ease-out 0.2s;
        padding-bottom: 5px;
    }
    .ui-card .description a {
        color: #fff;
        /* background:#34aff3; */
        display: inline-block;
        padding: 0px 20px;
        border-radius: 5px;
        text-decoration: none;
        transform: translateY(30px);
        opacity: 0;
        transition: all 0.3s ease-out 0.4s;
    }
    .h52{
        font-size: 8px;
    }
}