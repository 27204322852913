@tailwind base;
@tailwind components;
@tailwind utilities;

.box2 div:nth-child(1) {
    position: relative;
    width: 60px;
    height: 60px;
    background-color: transparent;
    border-radius: 16px;
    z-index: 100;
    margin-top: 10px;
    margin-left: 10px;
    /* display:none; */
    /* border: 6px solid rgb(245, 128, 226); */
}

@media screen and (max-width: 425px) {
    .navname2 {
        font-size: 9px;
    }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
    .navname2 {
        font-size: 13px;
    }
}

@media screen and (min-width: 768px) {
    .navname2 {
        font-size: 20px;
    }
}



.box2 {
    width: 60px;
    height: 60px;
    left: var(--ik);
    top: 100px;
    border-radius: 100%;
    background-color: transparent;
    position: absolute;
    border: 5px dotted rgb(242, 94, 255);
    animation: animate23 1s linear normal forwards;
    z-index: 99999;
}

@keyframes animate23 {
    0% {
        display: block;
        transform: scale(0) rotate(0);
        opacity: 1;
    }

    100% {
        transform: scale(1.2) rotate(100deg);
        opacity: 0;
    }
}

.abso {
    height: 100%;
    width: 100%;
    position: absolute;
}

::-webkit-scrollbar {
    /* width: 10px; */
    display: none;
}